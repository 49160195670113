<template lang="pug">
.base-modal-wrapper(
  :style="{ 'z-index': (modalIndex + 1) * 1000 }"
  @click.self="closeModal"
)
  .base-modal.add-references-modal.p-3(:style="sameModalOffsetStyle")
    span.base-modal-close(v-if="!referenceRecordSelected" @click.stop="closeModal")
      svg.icon
        use(xlink:href="#icon-times-solid")
    //- HEADER
    .base-modal-header

      .d-flex.align-items-center.w-100
        .select-list-search-icon.mr-2
          i.far.fa-search
        input.select-list-filter.flex-grow-1.mr-2.border-0(
          v-focus
          type="text"
          autocomplete="off"
          :placeholder="$t('searchReference')"
          v-model="filterElementsInput"
          :key="selectedElementId + elementObjectName"
        )
      hr.mt-1

    //- BODY
    .base-modal-body(v-if="entry.field.typeOptions.foreign_table_id")
      template(v-if="filteredElements.length")
        .d-flex.align-items-center.px-3.py-2.mx-3.rounded.border.my-2.entry-reference(
          v-for="reference in filteredElements"
          :key="reference.foreign_record_id"
          v-select-list-element="reference"
        )
          .text-nowrap.text-truncate(
            :title="reference.foreign_record_display_name"
          ) {{ reference.foreign_record_display_name }}

      .d-flex.align-items-center.justify-content-center.h-100(v-else-if="!recordsForReference")
        Loader
      .d-flex.align-items-center.justify-content-center.h-100(v-else)
        p.font-italic {{ $t('noRecordsFound') }}

    .base-modal-body(v-else)
      .d-flex.align-items-center.justify-content-center.h-100
        p.text-center.text-danger(v-html="$t('fields.noForeignTableId')")
    //- FOOTER
    .base-modal-footer
      hr
      .d-flex.justify-content-between.align-items-center
        .open-add-reference.d-flex.align-items-center(
          v-if="canCreateReferenceRecord"
          @click="createReferenceRecord"
        )
          span.add-reference-btn.mr-2
            i.fa.fa-plus
          span {{ $t('createNewRecord') }}
        p.mb-0
          small.font-weight-lighter {{ filteredElements.length }} {{ $tc('views.records', filteredElements.length) }}
</template>

<script>
import { BaseModalMixin }    from './BaseModalMixin';
import { EventBus }          from "../../main.js";
import { AddReferenceMixin } from '../../mixins/AddReferenceMixin';

export default {
  mixins: [BaseModalMixin, AddReferenceMixin],
  data() {
    return {
      entry:                          null,
      shouldNotOpenNewReferenceModal: false
    }
  },
  computed: {
    componentIsOnForeground() {
      return this.modalOpened;
    }
  },
  methods: {
    afterAddReference() {
      this.closeModal();
    },
    onModalOpen({ entry, shouldNotOpenNewReferenceModal }) {
      this.entry                          = entry;
      this.shouldNotOpenNewReferenceModal = shouldNotOpenNewReferenceModal;

      if (this.entry.field.typeOptions.foreign_table_id) {
        this.fetchRecordsForReference();
      }
    },
    beforeModalClose() {
      if (this.referenceRecordSelected) return 'abort';
    },
    onModalClose() {
      this.cleanSelectList();
      this.entry                          = null;
      this.recordsForReference            = null;
      this.shouldNotOpenNewReferenceModal = false;
    },
    afterCreateReferenceRecord(reference) {
      if (this.shouldNotOpenNewReferenceModal) return; // from EntryInputWriteReference
      this.openReferenceModal(reference);
    },
    openReferenceModal(reference) {
      EventBus.$emit('openModal', {
        modalName: 'RecordModal',
        modalProps: {
          id:      reference.foreign_record_id,
          tableId: this.entry.field.typeOptions.foreign_table_id
        }
      });
    }
  }
}
</script>
