import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";

export default class extends Controller {
  static targets = ["template", "fileInput", "dropzone"];
  static values = {"errorMessage": String, "acceptedFiles": {type: String, default: ".pdf"}};

  connect() {
    // this.fileInput = document.querySelector('#supply_invoice_attachment'); // Remplacer par l'ID de votre champ input de type file

    const dropzoneConfig = {
      url: "/",
      autoProcessQueue: false,
      clickable: true,
      maxFilesize: 256,
      addRemoveLinks: false,
      dictInvalidFileType: this.errorMessageValue,
      acceptedFiles: this.acceptedFilesValue,
      maxFiles: 1,
      previewTemplate: this.templateTarget.innerHTML
    };
    this.dropzone = new Dropzone(this.dropzoneTarget, dropzoneConfig);

    this.dropzone.on("addedfile", (file) => {
      const fileList = new DataTransfer();
      fileList.items.add(file);
      this.fileInputTarget.files = fileList.files;
    });

    this.dropzone.on("removedfile", (file) => {
      const fileList = new DataTransfer();
      Array.from(this.fileInputTarget.files).forEach((existingFile) => {
        if (existingFile.name !== file.name) {
          fileList.items.add(existingFile);
        }
      });
      this.fileInputTarget.files = fileList.files;
    });
  }
}
