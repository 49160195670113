<template lang="pug">
.base-modal-wrapper(
  :style="{ 'z-index': (modalIndex + 1) * 1000 }"
  @click.self="closeModal"
)
  .base-modal.entry-lookups-modal.p-3(:style="sameModalOffsetStyle")
    span.base-modal-close(@click.stop="closeModal")
      svg.icon
        use(xlink:href="#icon-times-solid")

    //- HEADER
    .base-modal-header
      p.mb-0.mt-2
       i.fal.mr-2(:class="'fa-'+ entry.field.faIconName")
       span(
        :class="{ [translationFallbackClasses]: entry.field.nameFallback }"
       ) {{ entry.field.name }}

    //- BODY
    .base-modal-body.pt-3
      template(v-if="entry.displayValue.length")
        .px-2.py-1.rounded.my-2.entry-lookup(
          v-for="displayValue in entry.displayValue"
        ) {{ displayValue }}
      .d-flex.align-items-center.justify-content-center.h-100(v-else)
        p.font-italic {{ $t('noRecords') }}
</template>

<script>
import { BaseModalMixin } from './BaseModalMixin';
import { LookupsMixin }   from '../../mixins/entries/LookupsMixin';
import { mapState } from 'vuex';

export default {
  mixins: [BaseModalMixin, LookupsMixin],
  data() {
    return {
      entry: null
    }
  },
  computed: {
    ...mapState(['translationFallbackClasses'])
  },
  methods: {
    onModalOpen(entry) {
      this.entry = entry;
    },
    onModalClose() {
      this.entry = null;
    }
  }
}
</script>
