<template lang="pug">
.base-modal-wrapper(
  :style="{ 'z-index': (modalIndex + 1) * 1000 }"
  @click.self="closeModal"
)
  .base-modal.add-references-modal.p-3(:style="sameModalOffsetStyle")
    span.base-modal-close(@click.stop="closeModal")
      svg.icon
        use(xlink:href="#icon-times-solid")
    //- HEADER
    .base-modal-header

      .d-flex.align-items-center.w-100
        .select-list-search-icon.mr-2
          i.far.fa-search
        input.select-list-filter.flex-grow-1.mr-2.border-0(
          v-focus
          type="text"
          autocomplete="off"
          :placeholder="$t('searchUser')"
          v-model="filterElementsInput"
          :key="selectedElementId + elementObjectName"
        )
      hr.mt-1

    //- BODY
    .base-modal-body
      template(v-if="filteredElements.length")
        .d-flex.align-items-center.px-3.py-2.mx-3.rounded.border.my-2.entry-reference(
          v-for="user in filteredElements"
          :key="user.id"
          v-select-list-element="user"
        )
          .text-nowrap.text-truncate(
            :title="user.displayName"
          ) {{ user.displayName }}

      .d-flex.align-items-center.justify-content-center.h-100(v-else-if="!usersForReference")
        Loader
      .d-flex.align-items-center.justify-content-center.h-100(v-else)
        p.font-italic {{ $t('noUsersFound') }}

    //- FOOTER
    .base-modal-footer
      hr
      .d-flex.justify-content-end.align-items-center
        p.mb-0
          small.font-weight-lighter {{ filteredElements.length }} {{ $tc('views.users', filteredElements.length) }}
</template>

<script>
import { BaseModalMixin } from './BaseModalMixin';
import { EventBus }       from "../../main.js";
import { AddUserMixin }   from '../../mixins/AddUserMixin';

export default {
  mixins: [BaseModalMixin, AddUserMixin],
  data() {
    return {
      entry: null
    }
  },
  computed: {
    componentIsOnForeground() {
      return this.modalOpened;
    }
  },
  methods: {
    afterAddUserForReference() {
      this.closeModal();
    },
    onModalOpen({ entry }) {
      this.entry = entry;
      this.fetchUsersForReference();
    },
    onModalClose() {
      this.cleanSelectList();
      this.entry             = null;
      this.usersForReference = null;
    },
  }
}
</script>
