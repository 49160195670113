<template lang="pug">
  .base-modal-wrapper(
    :style="{ 'z-index': (modalIndex + 1) * 1000 }"
    @click.self="closeModal"
  )
    .base-modal(:style="sameModalOffsetStyle")
      span.base-modal-close(@click.stop="closeModal")
        svg.icon
          use(xlink:href="#icon-times-solid")


      //- HEADER
      .base-modal-header.d-flex.align-items-center
        span.bg-indigo-100.p-2.rounded-circle.mr-2(style="line-height: 1.3rem;")
          i.far.fa-calendar-day.fa-fw.text-indigo-800
        .text-xl.text-gray-800.font-weight-bolder(
          v-if="isNewView"
        ) {{ $t('calendarModal.newCalendarView') }}
        .text-xl.text-gray-800.font-weight-bolder(
          v-else
        ) {{ $t('calendarModal.editCalendarView') }}

      //- BODY
      .base-modal-body.my-4


        .mt-2
          label.font-weight-bolder {{ $t('views.createViewPlaceholder') }}
          input.form-control(
            v-model="view.name"
            :placeholder="$t('views.createViewPlaceholder')"
          )

        // On souhaite prendre le primary field en titre par défaut mais la fonction n'est pas encore sèche
        .mt-3
          section
            label.font-weight-bolder {{ $t('calendarModal.title') }}
            .glint-select--wrapper-modal.mb-4
              select.glint-select--input.form-control(v-model="view.typeOptions.title_field_id")
                option(v-for="(field, index) in textFields" :value="field.id") {{ field.name }}
              i.fas.fa-chevron-down.glint-select--icon
        .mt-3
          section
            label.font-weight-bolder {{ $t('calendarModal.details') }}
            .glint-select--wrapper-modal.mb-4
              select.glint-select--input.form-control(v-model="view.typeOptions.details_field_id")
                option(v-for="(field, index) in textFields" :value="field.id") {{ field.name }}
              i.fas.fa-chevron-down.glint-select--icon

        .mt-2
          section
            label.font-weight-bolder {{ $t('calendarModal.endDateOption') }}
              .d-flex.align-items-center
                .toggle-item(v-for="option in ['range', 'end_date_field']")
                  input.toggle-selector(
                    v-model="view.typeOptions.end_date_option"
                    type="radio"
                    :value="option"
                    :id="option"
                  )
                  label(:for="option") {{ $t(`calendarModal.${option}`) }}

        .bg-gray-200.p-2.rounded
          .mt-2(v-if="view.typeOptions.end_date_option === 'range'")
            section
              label.font-weight-bolder {{ $t('fieldType.date_time_range') }}
              .glint-select--wrapper-modal.mb-4
                select.glint-select--input.form-control(v-model="view.typeOptions.date_time_range_field_id")
                  option(v-for="(field, index) in dateTimeRangeFields" :value="field.id") {{ field.name }}
                i.fas.fa-chevron-down.glint-select--icon

          .mt-2(v-if="view.typeOptions.end_date_option === 'end_date_field'")
            section
              label.font-weight-bolder {{ $t('calendarModal.startAtField') }}
              .glint-select--wrapper-modal.mb-4
                select.glint-select--input.form-control(v-model="view.typeOptions.date_start_at_field_id")
                  option(v-for="(field, index) in dateFields" :value="field.id") {{ field.name }}
                i.fas.fa-chevron-down.glint-select--icon

          .mt-2(v-if="view.typeOptions.end_date_option === 'end_date_field'")
            section
              label.font-weight-bolder {{ $t('calendarModal.endAtField') }}
              .glint-select--wrapper-modal.mb-4
                select.glint-select--input.form-control(v-model="view.typeOptions.date_end_at_field_id")
                  option(v-for="(field, index) in dateFields" :value="field.id") {{ field.name }}
                i.fas.fa-chevron-down.glint-select--icon

        .mt-2
          section
            label.font-weight-bolder {{ $t('calendarModal.reference') }}
            .glint-select--wrapper-modal.mb-4
              select.glint-select--input.form-control(v-model="view.typeOptions.reference_field_id")
                option(v-for="(field, index) in referenceFields" :value="field.id") {{ field.name }}
              i.fas.fa-chevron-down.glint-select--icon

        .mt-3
          section
            label.font-weight-bolder {{ $t('calendarModal.location') }}
            .glint-select--wrapper-modal.mb-4
              select.glint-select--input.form-control(v-model="view.typeOptions.location_field_id")
                option(v-for="(field, index) in textFields" :value="field.id") {{ field.name }}
              i.fas.fa-chevron-down.glint-select--icon

        .mt-3
          section
            label.font-weight-bolder {{ $t('calendarModal.organizer_email') }}
            .glint-select--wrapper-modal.mb-4
              select.glint-select--input.form-control(v-model="view.typeOptions.organizer_email_field_id")
                option(v-for="(field, index) in textFields" :value="field.id") {{ field.name }}
              i.fas.fa-chevron-down.glint-select--icon

        .mt-3
          section
            label.font-weight-bolder {{ $t('calendarModal.organizer_name') }}
            .glint-select--wrapper-modal.mb-4
              select.glint-select--input.form-control(v-model="view.typeOptions.organizer_name_field_id")
                option(v-for="(field, index) in textFields" :value="field.id") {{ field.name }}
              i.fas.fa-chevron-down.glint-select--icon

        .mt-3
          section
            label.font-weight-bolder {{ $t('calendarModal.attendees') }}
            .glint-select--wrapper-modal.mb-4
              select.glint-select--input.form-control(v-model="view.typeOptions.attendees_field_id")
                option(v-for="(field, index) in textFields" :value="field.id") {{ field.name }}
              i.fas.fa-chevron-down.glint-select--icon

      //- FOOTER
      .base-modal-footer.mt-4.d-flex.justify-content-between
        button.btn.btn-cancel(@click.stop="closeModal")
          span {{ $t('cancel') }}

        button.btn.default-btn(
          @click="createView"
          v-if="isNewView"
        ) {{ $t('views.createView') }}

        button.btn.default-btn(
          @click="updateView"
          v-else
        ) {{ $t('views.updateView') }}
</template>

<script>
import { api }                  from '../../api/client';
import { mapState, mapGetters } from 'vuex';
import { EventBus }             from "../../main";
import { BaseModalMixin }       from "./BaseModalMixin";
import { sortBy }               from "lodash";

export default {
  mixins: [BaseModalMixin],
  data() {
    return {
      view:      {},
      editView:  {},
      isNewView: false
    };
  },
  computed: {
    ...mapState({
      tableId: state => state.tableStore.table.id,
      currentView: state => state.viewStore.view,
      canCreateProfileView: state => state.feature.canCreateProfileView,
    }),
    ...mapGetters({
      currentFields: 'fieldStore/currentFields',
    }),
    dateTimeRangeFields() {
      return sortBy(
        this.currentFields.filter(field => field.dataType === "date_time_range"),
        "columnNumber"
      );
    },
    dateFields() {
      return sortBy(
        this.currentFields.filter(field => field.dataType === "date"),
        "columnNumber"
      );
    },
    textFields() {
      return sortBy(
        this.currentFields.filter(field => field.dataType != "date"),
        "columnNumber"
      );
    },
    referenceFields() {
      return sortBy(
        this.currentFields.filter(field => field.dataType === "references"),
        "columnNumber"
      );
    }
  },
  methods: {
    onModalOpen({ view = null }) {
      if (view) {
        this.view = this.createInitialViewObject(view);
        this.editView = view;
      } else {
        this.isNewView = true;
        this.view = this.createEmptyViewObject();
      }
    },
    createEmptyViewObject() {
      return {
        name: '',
        alert: false,
        typeOptions: {
          title_field_id: '',
          details_field_id: '',
          reference_field_id: '',
          date_time_range_field_id: '',
          date_start_at_field_id: '',
          date_end_at_field_id: '',
          end_date_option: '',
          location_field_id: '',
          organizer_email_field_id: '',
          organizer_name_field_id: '',
          attendees_field_id: '',
        },
        scope: this.canCreateProfileView ? 'profile' : 'personal'
      };
    },
    createInitialViewObject(view) {
      return {
        name:  view.name,
        alert: view.alert,
        typeOptions: {
          title_field_id: view.typeOptions.title_field_id,
          details_field_id: view.typeOptions.details_field_id,
          reference_field_id: view.typeOptions.reference_field_id,
          date_time_range_field_id: view.typeOptions.date_time_range_field_id,
          date_start_at_field_id: view.typeOptions.date_start_at_field_id,
          date_end_at_field_id: view.typeOptions.date_end_at_field_id,
          end_date_option: view.typeOptions.end_date_option,
          location_field_id: view.typeOptions.location_field_id,
          organizer_email_field_id: view.typeOptions.organizer_email_field_id,
          organizer_name_field_id: view.typeOptions.organizer_name_field_id,
          attendees_field_id: view.typeOptions.attendees_field_id,
        },
        scope: view.scope,
      };
    },
    onModalClose() {
      this.view      = null;
      this.editView  = null;
      this.isNewView = false;
    },
    updateView() {
      this.editView.name = this.view.name;
      this.editView.alert = this.view.alert;
      this.editView.typeOptions.title_field_id = this.view.typeOptions.title_field_id;
      this.editView.typeOptions.details_field_id = this.view.typeOptions.details_field_id;
      this.editView.typeOptions.reference_field_id = this.view.typeOptions.reference_field_id;
      this.editView.typeOptions.date_time_range_field_id = this.view.typeOptions.date_time_range_field_id;
      this.editView.typeOptions.date_stard_at_field_id = this.view.typeOptions.date_stard_at_field_id;
      this.editView.typeOptions.date_end_at_field_id = this.view.typeOptions.date_end_at_field_id;
      this.editView.typeOptions.end_date_option = this.view.typeOptions.end_date_option;
      this.editView.typeOptions.location_field_id = this.view.typeOptions.location_field_id;
      this.editView.typeOptions.organizer_email_field_id = this.view.typeOptions.organizer_email_field_id;
      this.editView.typeOptions.organizer_name_field_id = this.view.typeOptions.organizer_name_field_id;
      this.editView.typeOptions.attendees_field_id = this.view.typeOptions.attendees_field_id;

      this.view.id = this.currentView.id

      this.view.type_options = {}
      this.view.type_options.title_field_id = this.view.typeOptions.title_field_id;
      this.view.type_options.details_field_id = this.view.typeOptions.details_field_id;
      this.view.type_options.reference_field_id = this.view.typeOptions.reference_field_id;
      this.view.type_options.date_time_range_field_id = this.view.typeOptions.date_time_range_field_id;
      this.view.type_options.date_start_at_field_id = this.view.typeOptions.date_start_at_field_id;
      this.view.type_options.date_end_at_field_id = this.view.typeOptions.date_end_at_field_id;
      this.view.type_options.end_date_option = this.view.typeOptions.end_date_option;
      this.view.type_options.location_field_id = this.view.typeOptions.location_field_id;
      this.view.type_options.organizer_email_field_id = this.view.typeOptions.organizer_email_field_id;
      this.view.type_options.organizer_name_field_id = this.view.typeOptions.organizer_name_field_id;
      this.view.type_options.attendees_field_id = this.view.typeOptions.attendees_field_id;

      api.views.update({ view: this.view }).then(response => {
        this.editView.slug = response.data.view.slug;
        this.$store.dispatch('updateDocumentState', this.editView.buildDocumentState());
        this.closeModal();
      });
    },
    createView() {
      api.views.create({
        scope: this.view.scope,
        view: {
          name:         this.view.name,
          alert:        this.view.alert,
          table_id:     this.tableId,
          type_options: this.view.typeOptions,
          view_type:    'calendar',
        }
      }).then(response => {
          const viewData = response.data.viewData;
          this.$store.dispatch('viewStore/pushNewView', { viewData: viewData });
          this.$store.dispatch('viewStore/fetchView', { viewId: viewData.id });
          this.closeModal();
          EventBus.$emit('viewCreated');
        });
    },
  },
};
</script>
