import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "rankingWaitingForDataContainer",
    "rankingSortIcon",
    "rankingTableContainer",
    "rankingForm",
    "selectedFlexSiteId",
    "selectedActivityLevel",
    "selectedWorkHours",
  ];

  connect() {
    this.submitForm();
  }

  reverseOrder() {
    this.rankingSortIconTarget.classList.toggle("fa-sort-amount-up-alt");
    this.rankingSortIconTarget.classList.toggle("fa-sort-amount-down-alt");

    this.rankingTableContainerTarget.classList.toggle("tw-flex-col");
    this.rankingTableContainerTarget.classList.toggle("tw-flex-col-reverse");
  }

  displayFlexSiteDetails(event) {
    this.selectedFlexSiteIdTarget.value = event.currentTarget.dataset.flexSiteId;
    this.selectedActivityLevelTarget.value = event.currentTarget.dataset.activityLevel;
    this.selectedWorkHoursTarget.value = event.currentTarget.dataset.workHours;

    this.submitForm();
  }

  isWaitingForData() {
    return this.rankingWaitingForDataContainerTarget.dataset.waitingForData == 1;
  }

  submitForm() {
    // <form>.requestSubmit() is not supported on Safari prior to version 16 (2022/09/12),
    // but requestSubmit triggers html form validations, which are nice for this form,
    // so if available, use requestSubmit, else fallback to classic submit.
    // NB: even on Safari 16, requestSubmit does not support passing a submit btn
    // to simulate a submit from this btn.
    // NB2: feels like Safari wanna play IE style ...

    if (this.isWaitingForData() || !this.hasRankingFormTarget) return;

    this.dispatch("updated");

    if (this.rankingFormTarget.requestSubmit) {
      this.rankingFormTarget.requestSubmit();
    } else {
      this.rankingFormTarget.submit();
    }
  }
}
