<template lang="pug">

    .base-modal-wrapper(
      :style="{ 'z-index': (modalIndex + 1) * 1000 }"
      @click.self="closeModal"
    )
      .base-modal.file-manager--modal(
        :class="{ 'w-100 mobile': isOnMobile }"
        :style="sameModalOffsetStyle"
      )
        span.base-modal-close(@click.stop="closeModal")
          svg.icon
            use(xlink:href="#icon-times-solid")

        .base-modal-header.file-manager--header
          h5.mb-4.text-center
            i.far.fa-file-upload.text-info
            span.text-uppercase.ml-2(
              :class="{ [translationFallbackClasses]: entry.field.nameFallback }"
            )
              | {{ entry.field.name }}
              span(v-if="entry.isWritable") &nbsp;- {{ $t('fileManager.fileManager') }}

        div(v-if="entry.isWritable")
          h6.file-manager--files-title {{ $t('fileManager.newFilesToUpload') }}
          .position-relative
            vue-dropzone.file-manager--dropzone-component(
              ref="fileManagerDropzone"
              :id="'fileManagerDropzone' + entry.field.id + '-' + entry.record.id"
              :options="dropzoneOptions"
              @vdropzone-thumbnail="thumbnail"
              @vdropzone-file-added="fileAdded"
              @vdropzone-success-multiple="successMultiple"
            )
            .spinner-container.d-none
              p.text-center
                h6.file-manager--dropzone-title {{ $t('fileManager.processing') }}
                p.file-manager--dropzone-subtitle {{ $t('fileManager.operationCanTakeAFewSeconds') }}
              .sk-circle(style="margin: 0; width: 3rem; height: 3rem;")
                .sk-child(v-for="n in 12" :class="'sk-circle' + n")

          .d-flex.mt-2
            .ctrlbar-action--btn(@click="processQueue") {{ $t('fileManager.upload') }}

        .file-manager--errors-container.mt-3

        h6.file-manager--files-title {{ $t('fileManager.myFiles') }}
        .file-manager--files-container
          ProjectFile(
            v-for="projectFile in entry.value"
            :key="entry.field.id + '-' + projectFile.id"
            :projectFile="projectFile"
            :fileType="getFileType(projectFile.filename, projectFile.content_type)"
            :entry="entry"
            :allowDelete="entry.isWritable"
          )
</template>

<script>
import { mapState }       from 'vuex';
import { EventBus }       from '../../main.js';
import ProjectFile        from '../ProjectFile.vue';
import { EntryFileMixin } from '../../mixins/EntryFileMixin';
import { BaseModalMixin } from '../modals/BaseModalMixin';

export default {
  components: {
    ProjectFile,
  },
  mixins: [BaseModalMixin, EntryFileMixin],
  data() {
    return {
      entry: null
    }
  },
  computed: {
    ...mapState(['translationFallbackClasses']),
    ...mapState({
      uniqSessionId: state => state.uniqSessionId
    }),
    dropzoneOptions() {
      if(this.entry) {
        return {
          autoProcessQueue: false,
          uploadMultiple: true,
          url: `/records/${this.entry.record.id}/entry/project_files`,
          params: {
            field_id: this.entry.field.id
          },
          maxFilesize: 100,
          paramName: 'project_files',
          headers: {
            'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
            'Accept': 'application/json',
            'X-UNIQ-SESSION-ID': this.uniqSessionId
          },
          previewTemplate: this.template(),
          dictDefaultMessage: this.dropzoneDefaultMessage(),
        }
      }
    },
    isOnMobile() {
      return this.mainPanelWidth < 610;
    }
  },
  mounted() {
    EventBus.$on('projectFileDeleted', (projectFile, response) => {
      const index = this.entry.value.indexOf(projectFile);
      if (index > -1) {
        this.entry.value.splice(index, 1);
      }
    })
  },
  methods: {
    onModalOpen(entry) {
      this.entry = entry;
    },
    onModalClose() {
      this.entry = null;
    },
    processQueue() {
      if (this.$refs.fileManagerDropzone.getQueuedFiles().length !== 0) {
        const errorsContainer = document.querySelector('.file-manager--errors-container');
        const spinner         = document.querySelector('.spinner-container');

        this.$refs.fileManagerDropzone.processQueue();
        errorsContainer.innerHTML = "";
        spinner.classList.remove('d-none');
      }
    },
    dropzoneDefaultMessage() {
      return `
        <div class="file-manager--dropzone">
          <div class="mb-3">
            <i class="fas fa-file-plus file-manager--dropzone-icon"></i>
          </div>
          <h6 class="file-manager--dropzone-title">` +
            this.$t('fileManager.dropzoneDragAndDrop') +
          `</h6>
          <div class="file-manager--dropzone-subtitle">` +
            this.$t('fileManager.dropzoneClickToSelectAFileFromYourComputer') +
          `</div>
        </div>
      `;
    },
    template() {
      return `
        <div class="file-manager--preview dz-preview dz-file-preview">
          <i class="fas fa-times-circle file-manager--delete-icon dz-remove" data-dz-remove=""></i>
          <div class="dz-image">
            <div data-dz-thumbnail-bg>
              <i class="fas file-manager--file-icon"></i>
            </div>
          </div>
          <div class="dz-details file-manager--details">
            <div class="dz-filename"><span data-dz-name class="file-manager--preview-filename"></span></div>
          </div>
          <!--<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>-->
          <div class="dz-error-message"><span data-dz-errormessage></span></div>
        </div>
      `;
    },
    thumbnail(file, dataUrl) {
      let j, len, ref, thumbnailElement;
      if (file.previewElement) {
        file.previewElement.classList.remove("dz-file-preview");
        const ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
        for (j = 0, len = ref.length; j < len; j++) {
          thumbnailElement = ref[j];
          thumbnailElement.alt = file.name;
          thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
        };
        return setTimeout(((function(_this) {
          return function() {
            return file.previewElement.classList.add("dz-image-preview");
          };
        })(this)), 1);
      }
    },
    fileAdded(file) {
      if (file.previewElement) {
        const previewIcon = file.previewElement.querySelector(".file-manager--file-icon");

        if (previewIcon) {
          const previewThumbnail = file.previewElement.querySelector("[data-dz-thumbnail-bg]");
          const fileType         = this.getFileType(file.name, file.type);

          if (!['file', 'image'].includes(fileType)){
            previewThumbnail.innerHTML = `<i class="fas fa-${fileType} file-manager--file-icon"></i>`;
          }
        }
      }
    },
    successMultiple(files, response) {
      const errorsContainer = document.querySelector('.file-manager--errors-container');
      const spinner         = document.querySelector('.spinner-container');

      response.projectFiles.forEach((projectFile) => this.entry.value.push(projectFile));

      files.forEach((file) => this.$refs.fileManagerDropzone.removeFile(file));

      spinner.classList.add('d-none');

      response.errors.forEach((projectFileErrorMessage) => {
        errorsContainer.insertAdjacentHTML('beforeend', `<strong>${projectFileErrorMessage[0]}</strong> ${projectFileErrorMessage[1]}<br>`);
      });

      setTimeout(() => errorsContainer.innerHTML = "", 60000);
    },
  }
}
</script>
