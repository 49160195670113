<template lang="pug">
.base-modal-wrapper(
  :style="{ 'z-index': (modalIndex + 1) * 1000 }"
  @click.self="closeModal"
)
  .base-modal(:style="sameModalOffsetStyle")
    span.base-modal-close(@click.stop="closeModal")
      svg.icon
        use(xlink:href="#icon-times-solid")


    //- HEADER
    .base-modal-header.d-flex.align-items-center
      span.bg-indigo-100.p-2.rounded-circle.mr-2(style="line-height: 1.3rem;")
        i.far.fa-th-large.fa-fw.text-indigo-800
      .text-xl.text-gray-800.font-weight-bolder {{ $t('formModal.newFormView')}}

    //- BODY
    .base-modal-body.my-4
      .mt-2
        label.font-weight-bolder {{ $t('views.createViewPlaceholder') }}
        input.form-control(
          v-model="newView.name"
          :placeholder="$t('views.createViewPlaceholder')"
        )

    //- FOOTER
    .base-modal-footer.mt-4.d-flex.justify-content-between
      button.btn.btn-cancel(@click.stop="closeModal")
        span {{ $t('cancel') }}

      button.btn.default-btn(@click="createView") {{ $t('views.createView') }}
</template>

<script>
import { api }            from '../../api/client';
import { mapState }       from 'vuex';
import { EventBus }       from "../../main";
import { BaseModalMixin } from "./BaseModalMixin";

export default {
  mixins: [BaseModalMixin],
  data() {
    return {
      newView: {
        name: '',
        alert: false
      },
      scope: null,
      typeOptions: {
        titleFieldId: ''
      }
    };
  },
  computed: {
    ...mapState({
      tableId: state => state.tableStore.table.id,
      canCreateProfileView: state => state.feature.canCreateProfileView,
    })
  },
  mounted() {
    this.scope = this.canCreateProfileView ? 'profile' : 'personal'
  },
  methods: {
    onModalOpen() {
    },
    onModalClose() {
    },
    createView() {
      if (this.newView.name === '') return;

      api.views.create({
        scope: this.scope,
        view: {
          ...this.newView,
          table_id: this.tableId,
          type_options: JSON.stringify(this.typeOptions),
          view_type: 'form',
        }
      }).then(response => {
          const viewData = response.data.viewData
          this.$store.dispatch('viewStore/pushNewView', { viewData: viewData })
          this.$store.dispatch('viewStore/fetchView', { viewId: viewData.id })
          this.newView = { name: '', alert: false }
          this.closeModal();
          EventBus.$emit('viewCreated')
        })
    }
  },
};
</script>
