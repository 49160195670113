import Appsignal from "@appsignal/javascript";

let key;

if (process.env.NODE_ENV === 'development') {
  key = null;
} else {
  // <meta content="xxxx" name="appsignal-frontend-api-key"></meta>
  key = document.querySelector('meta[name="appsignal-frontend-api-key"]')?.getAttribute('content');
}

// appsignal monitoring is initialized when configured with Vue (even for vanilla JS parts)
const appsignal = new Appsignal({ key });

export default appsignal;
