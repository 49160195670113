<template lang="pug">
  .base-modal-wrapper(
    :style="{ 'z-index': (modalIndex + 1) * 1000 }"
  )

    .base-modal(:style="sameModalOffsetStyle")
      span.base-modal-close(@click.stop="closeImportDataModal")
        svg.icon
          use(xlink:href="#icon-times-solid")

      //- HEADER
      .base-modal-header
        h5.mb-4.text-center
            i.far.fa-file-upload.text-info
            span.text-uppercase.ml-2
              | {{ $t('controlBarActions.importData') }}
      //- BODY
      .base-modal-body.mb-2
        template(v-if="errorAttributes")
          p
            | {{ $t('importDataModal.importFailed') }}
            span(
              v-if="errorAttributes.line_number"
              v-html="$t('importDataModal.atLineNumber', { line_number: errorAttributes.line_number })"
            )
            | {{ $t('importDataModal.becauseFollowingError') }}
          p.p-3.font-weight-bold.text-danger.rounded.bg-gray-200 {{ errorAttributes.message }}
          p.text-underline(v-if="errorAttributes.type === 'DataImport::InvalidImportedRecordError'")
            | {{ $t('importDataModal.importFailedWarning') }}
          button.btn.btn-info.d-block.mx-auto(@click="reloadPage") OK
        template(v-else)
          vue-dropzone(
            ref="dataImportDropzone"
            id="'dataImportDropzone'+ newdataImport.id"
            :options="dropzoneOptions"
            :include-styling="false"
            @vdropzone-error="handleError"
          )
          .dropzone-override.dropzone.mb-5
            .dropzone-helper
              svg.icon.dropzone-icon
                use(xlink:href="#icon-upload")

          .dropzone-previews.mb-4

          .dz-default.dz-message.text-center
            span {{ $t('importDataModal.dzMessage') }}

          .d-flex.align-items-center
            v-select.data-import-params-select.my-3(
              :searching="true"
              :options="dataImportParamsOptions"
              label="name"
              placeholder="Choisir un template d'import"
              :reduce="option => option.id"
              v-model="selectedDataImportParamsId"
              :append-to-body="true"
            )
            .ml-2.d-flex.justify-content-center(v-if="selectedDataImportParamsTemplate")
              a.btn.default-btn.d-flex.align-items-center(
                :href="`/data_import_params_template?data_import_params_id=${selectedDataImportParamsId}`"
                target="_blank" rel="noopener noreferrer"
              )
                i.far.fa-download.fa-fw.mr-2
                .text-nowrap Télécharger le template

          .spinner-container.d-none
            p.text-center
              .file-manager--dropzone-title {{ $t('fileManager.processing') }}
              .file-manager--dropzone-subtitle {{ $t('fileManager.operationCanTakeAFewMinutes') }}
            .sk-circle(style="margin: 0; width: 3rem; height: 3rem;")
              .sk-child(v-for="n in 12" :class="'sk-circle' + n")


          input(
            type="hidden"
            id="progressLoadingMessage"
            name="progressLoadingMessage"
            :value="$t('importDataModal.loadingMessage')"
          )

        //- FOOTER
        .base-modal-footer.mt-4.d-flex.justify-content-center
          button.btn.default-btn.btn-success.dropzone-submit(
            v-if="!errorAttributes"
            @click="createDataImport"
          ) {{ $t('importDataModal.importFile') }}
</template>

<script>
  import { api }                 from '../../api/client';
  import { mapState }            from 'vuex';
  import { BaseModalMixin }      from "./BaseModalMixin";
  import i18n                    from "../../locales/locales.js";
  import { requireConfirmation } from '../../../components/require_confirmation';

  export default {
    mixins: [BaseModalMixin],
    data() {
      return {
        newDataImport: false,
        dataImportParamsForCurrentTable: [],
        selectedDataImportParamsId: null,
        inProgress: false,
        errorAttributes: null
      };
    },
    computed: {
      ...mapState({
        projectTables: state => state.tableStore.tables,
        currentTable: state => state.tableStore.table,
        uniqSessionId: state => state.uniqSessionId
      }),
      dropzoneOptions() {
        return {
          autoProcessQueue: false,
          uploadMultiple: false,
          maxFiles: 1,
          url: `/api/tables/${this.currentTable.id}/data_imports`,
          maxFilesize: 200,
          paramName: 'data_import[data_file]',
          acceptedFiles: '.xlsx, .csv, .zip',
          headers: {
            'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
            'Accept': 'application/json',
            'X-UNIQ-SESSION-ID': this.uniqSessionId
          },
          clickable: '.dropzone-helper',
          previewsContainer: '.dropzone-previews',
          previewTemplate: this.template(),
          dictDefaultMessage: this.dropzoneDefaultMessage(),
        }
      },
      dataImportParamsOptions() {
        return this.dataImportParamsForCurrentTable.concat({ name: i18n.t('default'), id: -1 });
      },
      selectedDataImportParams() {
        return this.dataImportParamsForCurrentTable.find((dataImportParams) => {
          return dataImportParams.id === this.selectedDataImportParamsId;
        });
      },
      selectedDataImportParamsTemplate() {
        if (!this.selectedDataImportParams) return;

        return this.selectedDataImportParams.template;
      },
    },
    watch: {
      selectedDataImportParamsId: function(_newDataImportParams, _oldDataImportParams) {
        this.$refs.dataImportDropzone.dropzone.options.params = { data_import_params_id: this.selectedDataImportParamsId };
      },
    },
    methods: {
      dropzoneDefaultMessage() {
        return `
        `;
      },
      template() {
        return `
          <div class="dz-preview dz-file-preview">
            <div class="dz-details">
              <div class="file-import-filename-container">
                <i class="fad fa-file-spreadsheet file-import-icon"></i>
                <i class="fas fa-times-circle csv-import-delete-icon dz-remove" data-dz-remove=""></i>
              </div>
              <div class="dz-filename text-center">
                <span data-dz-name class="file-import-filename"></span>
              </div>
              <div class="dz-error-message">
                <span data-dz-errormessage></span>
              </div>
            </div>
          </div>
        `;
      },
      onModalOpen() {
        return this.getDataImportParamsForCurrentTable();
      },
      getDataImportParamsForCurrentTable() {
        api.dataImportParams.forCurrentTable({
          currentTableId: this.currentTable.id,
        }).then(response => {
          this.dataImportParamsForCurrentTable = response.data
          this.selectedDataImportParamsId = this.dataImportParamsOptions[0].id;
        });
      },
      createDataImport() {
        if (this.$refs.dataImportDropzone.getQueuedFiles().length !== 0 && this.selectedDataImportParamsId) {
          const spinner = document.querySelector('.spinner-container');

          this.$refs.dataImportDropzone.processQueue();
          this.inProgress = true;
          spinner.classList.remove('d-none');
        }
      },
      closeImportDataModal() {
        if (this.inProgress || this.$refs.dataImportDropzone.getQueuedFiles().length !== 0) {
          requireConfirmation(
            this.$t('importDataModal.CloseModalConfirmation'),
            this.$t('confirm'),
            this.$t('cancel'),
          ).then((result) => {
            if(result.isConfirmed) this.closeModal();
          })
        } else {
          this.closeModal();
        }
      },
      handleError(_file, errorAttributes, xhr) {
        if (errorAttributes.error && xhr.status === 422) {
          this.inProgress = false;
          this.errorAttributes = errorAttributes
        }
      },
      reloadPage() {
        window.location.reload()
      }
    }
  };
</script>
