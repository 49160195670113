<template lang="pug">
.base-modal-wrapper(
  :style="{ 'z-index': (modalIndex + 1) * 1000 }"
  @click.self="closeModal"
)
  .base-modal(:style="sameModalOffsetStyle")
    span.base-modal-close(@click.stop="closeModal")
      svg.icon
        use(xlink:href="#icon-times-solid")


    //- HEADER
    .base-modal-header.d-flex.align-items-center
      span.bg-indigo-100.p-2.rounded-circle.mr-2(style="line-height: 1.3rem;")
        i.far.fa-pen.fa-fw.text-indigo-800
      .text-xl.text-gray-800.font-weight-bolder {{ $t('editViewDescriptionModal.editViewDescription')}}

    //- BODY
    .base-modal-body.my-4
      textarea.form-control.w-100(
        rows=16
        v-model="viewDescription"
        :placeholder="$t('editViewDescriptionModal.viewDescriptionPlaceholder')"
        :class="{ [translationFallbackClasses]: currentView.descriptionFallback }"
      )

    //- FOOTER
    .base-modal-footer.mt-4.d-flex.justify-content-between
      button.btn.btn-cancel(@click.stop="closeModal")
        span {{ $t('cancel') }}

      button.btn.default-btn(@click="UpdateViewDescription") {{ $t('save') }}
</template>

<script>
import { api }            from '../../api/client';
import { mapState }       from 'vuex';
import { BaseModalMixin } from "./BaseModalMixin";

export default {
  mixins: [BaseModalMixin],
  data() {
    return {
      viewDescription: null,
    }
  },
  computed: {
    ...mapState({
      currentView: state => state.viewStore.view,
      translationFallbackClasses: state => state.translationFallbackClasses,
    })
  },
  mounted() {
    this.viewDescription = this.currentView.description;
  },
  methods: {
    onModalOpen() {
    },
    onModalClose() {
    },
    UpdateViewDescription() {
      api.views.update({
        view: {
          id: this.currentView.id,
          description: this.viewDescription,
        }
      }).then(response => {
          this.currentView.description = response.data.view.description;
          this.closeModal();
        })
    }
  },
};
</script>
